import balanceText from '../vendor/text-balancer.js';

(async function () {
	await document.fonts.ready;

	balanceText(
		[
			'[js-balance-text]',
			'.text-preset-h1:not([js-no-balance-text])',
			'.text-preset-h2:not([js-no-balance-text])',
			'.text-preset-h3:not([js-no-balance-text])',
			'h1:not([js-no-balance-text])',
			'h2:not([js-no-balance-text])',
			'h3:not([js-no-balance-text])',
		].join(', '),
	);
})();
