import {consent} from './consent.js';
import {$$} from '../utils/query-selector.js';

consent('tracking').then(
	() => {
		console.info('Tracking consent given. Trackers will be loaded.');

		const $div = document.createElement('div');
		$div.innerHTML = window.seedling.trackers;

		for (const $script of $$('script', $div)) {
			const $newScript = document.createElement('script');
			for (let attr of $script.attributes) {
				$newScript.setAttribute(attr.name, attr.value);
			}
			let inlineScript = document.createTextNode($script.innerText);
			$newScript.appendChild(inlineScript);
			document.body.appendChild($newScript);
		}
	},
	() => {
		console.info('Tracking consent rejected. Trackers will not be loaded.');
	},
);
