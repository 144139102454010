import 'wicg-inert';
import dialogPolyfill from 'dialog-polyfill';
import Swiper, {A11y, Keyboard, Navigation} from 'swiper';
import {$, $$} from '../utils/query-selector.js';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';

class Ideologies {
	constructor($el) {
		this.$el = $el;
		this.$$links = $$('[js-ideologies__link]', $el);
		this.$dialog = $('[js-ideologies__dialog]', $el);
		this.$close = $('[js-ideologies__close]', $el);
		dialogPolyfill.registerDialog(this.$dialog);
		this.$swiper = $('[js-ideologies__swiper]', $el);
		this.$$slides = $$('[js-ideologies__slide', $el);

		this.$dialog.addEventListener('close', () => this.pauseVideos());

		this.initSlideshow();
		this.initLinks();
	}

	initLinks() {
		this.$$links.forEach(($item) =>
			$item.addEventListener('click', (e) => {
				const i = parseInt(e.currentTarget.dataset.articleIndex, 10);
				this.swiper.slideToLoop(i, 0);
				this.showDialog();
			}),
		);
	}

	initSlideshow() {
		this.swiper = new Swiper(this.$swiper, {
			modules: [A11y, Keyboard, Navigation],
			loop: false,
			keyboard: true,
			a11y: {
				enabled: true,
				firstSlideMessage: 'Erste Ideologie',
				lastSlideMessage: 'Letzte Ideologie',
				nextSlideMessage: 'Nächste Ideologie',
				prevSlideMessage: 'Vorherige Ideologie',
			},
			navigation: {
				prevEl: '[js-ideologies__prev]',
				nextEl: '[js-ideologies__next]',
				disabledClass: 'is-disabled',
				lockClass: 'is-locked',
				hiddenClass: 'is-hidden',
				navigationDisabledClass: 'is-disabled',
			},
		});

		for (let i = 1; i < this.$$slides.length; i++) {
			this.$$slides[i].setAttribute('aria-hidden', 'true');
			this.$$slides[i].inert = true;
		}

		this.swiper.on('slideChange', (swiper) => {
			this.$$slides.forEach(($item, index) => {
				$item.setAttribute(
					'aria-hidden',
					(swiper.realIndex !== index).toString(),
				);
				$item.inert = swiper.realIndex !== index;
			});
			this.pauseVideos();
		});

		// const focusTrapCallback = (e) => {
		// 	if (e.key !== 'Tab') {
		// 		return;
		// 	}

		// 	const $$focusable = [
		// 		...this.$dialog.querySelectorAll(
		// 			[
		// 				'a[href]',
		// 				'area[href]',
		// 				'input:not([disabled])',
		// 				'select:not([disabled])',
		// 				'textarea:not([disabled])',
		// 				'button:not([disabled])',
		// 				'iframe',
		// 				'object',
		// 				'embed',
		// 				'*[tabindex]',
		// 				'*[contenteditable]',
		// 			].join(', '),
		// 		),
		// 	].filter(($el) => {
		// 		if ($el.closest('[aria-disabled="true"]') !== null) {
		// 			return false;
		// 		}

		// 		if (!$el.closest('.swiper')) {
		// 			return true;
		// 		}

		// 		if ($el.closest('.swiper-slide:not(.swiper-slide-active)')) {
		// 			return false;
		// 		}

		// 		if ($el.offsetParent === null) {
		// 			return false;
		// 		}

		// 		return true;
		// 	});

		// 	const $firstFocusable = $$focusable[0];
		// 	const $lastFocusable = $$focusable[$$focusable.length - 1];

		// 	const currentIndex = [...$$focusable].findIndex(
		// 		($el) => document.activeElement === $el,
		// 	);

		// 	if (currentIndex === -1) {
		// 		return; // something strange happended …
		// 	}

		// 	if (e.shiftKey) {
		// 		// go backwards
		// 		if (document.activeElement === $firstFocusable) {
		// 			$lastFocusable.focus();
		// 		} else {
		// 			$$focusable[currentIndex - 1].focus();
		// 		}
		// 	} else {
		// 		// go forwards
		// 		if (document.activeElement === $lastFocusable) {
		// 			$firstFocusable.focus();
		// 		} else {
		// 			$$focusable[currentIndex + 1].focus();
		// 		}
		// 	}

		// 	e.preventDefault();
		// };

		// this.$dialog.addEventListener('keydown', focusTrapCallback);
	}

	pauseVideos() {
		this.$$slides.forEach(($item) => {
			const $iframe = $('iframe', $item);
			if ($iframe) {
				$iframe.contentWindow.postMessage(
					JSON.stringify({
						event: 'command',
						func: 'pauseVideo',
						args: [],
					}),
					'*',
				);
			}
		});
	}

	showDialog() {
		this.$dialog.showModal();
		this.$close.focus();
		// this.$$slides[this.swiper.activeIndex].focus();
	}
}

$$('[js-ideologies]').forEach(($el) => new Ideologies($el));
