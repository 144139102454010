import dialogPolyfill from 'dialog-polyfill';
import {$, $$} from '../utils/query-selector.js';
import {initElement as initEmbed} from './embed.js';

class HomeHero {
	constructor($el) {
		this.$el = $el;
		this.$play = $('[js-home-hero__play]', $el);

		if (!this.$play) {
			return;
		}

		this.$dialog = $('[js-home-hero__dialog]', $el);
		dialogPolyfill.registerDialog(this.$dialog);
		this.$embed = $('[js-home-hero__embed]', $el);
		this.embed = initEmbed(this.$embed);

		if (!this.$embed) {
			return;
		}

		this.$play.addEventListener('click', () => this.playVideo());

		this.$dialog.addEventListener('close', () => {
			this.embed.pause();
		});
	}

	playVideo() {
		this.$dialog.showModal();
		this.embed.play();
	}
}

$$('[js-home-hero]').forEach(($el) => new HomeHero($el));
