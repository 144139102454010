import {$, $$} from '../utils/query-selector.js';
import {ask} from './consent.js';

/**
 * Third-party components embedded via iframe with 2-click consent.
 */
class Embed {
	constructor($el) {
		this.$el = $el;
		this.$poster = $('[js-embed__poster]', $el);
		this.$consent = $('[js-embed__consent]', $el);
		this.$load = $('[js-embed__load]', $el);

		this.isLoaded = false;

		this.$load.addEventListener('click', (e) => this.load(e));
		this.$el._embed = this;
	}

	async consent() {
		try {
			await ask('embed', {
				providerName: this.$el.dataset.providerName,
				providerUrl: this.$el.dataset.providerUrl,
				providerPrivacyUrl: this.$el.dataset.providerPrivacyUrl,
			});
			return true;
		} catch (e) {
			return false;
		}
	}

	async load(e) {
		if (this.isLoaded) {
			return;
		}

		if (!(await this.consent())) {
			return;
		}

		this.$iframe = document.createElement('iframe');
		const allow = this.$el.dataset.allow;
		this.$iframe.setAttribute('allow', allow);
		if (allow.split(' ').includes('allowfullscreen')) {
			this.$iframe.setAttribute('allowfullscreen', 'allowfullscreen');
		}

		this.$iframe.className = this.$el.dataset.class;
		this.$iframe.setAttribute('src', this.$el.dataset.src);
		this.$iframe.setAttribute('tabindex', '0');

		this.$consent.after(this.$iframe);
		this.$poster.hidden = true;
		this.$consent.hidden = true;
		this.isLoaded = true;

		this.$iframe.focus({preventScroll: true});
	}

	unload() {
		if (!this.isLoaded) {
			return;
		}

		if (!this.$iframe) {
			return;
		}

		this.$iframe.remove();
		delete this.$iframe;
		this.$poster.hidden = false;
		this.$consent.hidden = false;
		this.isLoaded = false;
	}

	async play() {
		await this.load();

		if (!this.$iframe) {
			return;
		}

		this.$iframe.contentWindow.postMessage(
			JSON.stringify({
				event: 'command',
				func: 'playVideo',
				args: [],
			}),
			'*',
		);

		this.$iframe.focus({preventScroll: true});
	}

	pause() {
		if (!this.isLoaded) {
			return;
		}

		this.$iframe.contentWindow.postMessage(
			JSON.stringify({
				event: 'command',
				func: 'pauseVideo',
				args: [],
			}),
			'*',
		);
	}
}

function init(context = document) {
	const embeds = [];

	$$('[js-embed]', context).forEach(($el) => embeds.push(new Embed($el)));

	return embeds;
}

function initElement($el) {
	if ($el._embed) {
		return $el._embed;
	}

	return new Embed($el);
}

export {init, initElement};
export default Embed;

init();
