import {$, $$} from '../utils/query-selector.js';

const COPY_SUPPORTED = 'clipboard' in navigator;

$$('[js-copy-to-clipboard]').forEach(($el) => {
	if (!COPY_SUPPORTED) {
		$el.remove();
		return;
	}

	$el.hidden = false;

	const $button = $('button', $el);
	const $text = $('[js-copy-to-clipboard__text]', $button);
	const defaultText = $text.innerHTML;

	let statusTimeout = null;

	const status = (text) => {
		clearTimeout(statusTimeout);
		$text.innerHTML = text;
		window.setTimeout(() => ($text.innerHTML = defaultText), 2000);
	};

	$button.addEventListener('click', () => {
		navigator.clipboard.writeText($button.value).then(
			() => {
				status('kopiert!');
			},
			() => {
				status('Fehler!');
			},
		);
	});
});
